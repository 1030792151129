import React, {useContext} from 'react';
import "./TypeBar.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import closeImg from "../../img/close.svg";

function TypeBar({isSettingsVisible, setIsSettingsVisible}) {
	const {item} = useContext(Context);

	const handleSelectItem = (type) => {
		item.setSelectedType(type);
		setIsSettingsVisible(false);
	}
	return (
		<div className="type_bar">
			<h2>Каталог:</h2>
			<ul>
				{item.types.map(type => (
					<li
						className={type.id === item.selectedType.id ? "__item active" : "__item"}
						onClick={() => handleSelectItem(type)}
						key={type.id}>{type.name}
					</li>
				))}
			</ul>
			{isSettingsVisible &&
				<button onClick={() => setIsSettingsVisible(false)}>
					<img src={closeImg} alt="img"/>
				</button>
			}
		</div>
	);
}

export default observer(TypeBar);