import React, {useContext, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import "./AdminMenu.scss";
import mainImg from '../../img/adminImg.svg';
import cartImg from "../../img/cartImg.svg";
import giftImg from "../../img/giftImg.svg";
import deliveryImg from "../../img/adminDeliveryImg.svg";
import logoutImg from "../../img/adminLogout.svg";


function AdminMenu({setSelectedButton}) {
	const {user} = useContext(Context);
	const navigate = useNavigate();
	const [selectedItem, setSelectedItem ] = useState("main");

	const handleButtonChange = (data) => {
		setSelectedButton(data);
		setSelectedItem(data);
	}
	const handleLogout = () => {
		localStorage.removeItem('token');
		user.setIsAuth(false);
		user.setUser({});
		navigate("/");
	}
	return (
		<div className="__menu">
			<div className="__menu_item">
				<button className={`__list_item ${selectedItem === 'main' ? 'selected' : ''}`}
				        onClick={() => handleButtonChange("main")}>
					<div className="__image">
						<img src={mainImg} alt="img"/>
					</div>
					<span>Главная</span>
				</button>
			</div>
			<div className="__menu_item">
				<button className={`__list_item ${selectedItem === 'order' ? 'selected' : ''}`}
				        onClick={() => handleButtonChange("order")}>
					<div className="__image">
						<img src={cartImg} alt="img"/>
					</div>
					<span>Заказы</span>
				</button>
			</div>
			<div className="__menu_item">
				<button className={`__list_item ${selectedItem === 'gift' ? 'selected' : ''}`}
				        onClick={() => handleButtonChange("gift")}>
					<div className="__image">
						<img src={giftImg} alt="img"/>
					</div>
					<span>Подарок</span>
				</button>
			</div>
			<div className="__menu_item">
				<button className='__list_item'
				        onClick={handleLogout}>
					<div className="__image">
						<img src={logoutImg} alt="img"/>
					</div>
					<span>Выйти</span>
				</button>
			</div>
		</div>
	);
}

export default observer(AdminMenu);