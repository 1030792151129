import React, {useState} from 'react';
import "./Admin.scss";
import AdminMain from "../../components/AdminMain/AdminMain";
import AdminOrder from "../../components/AdminOrder/AdminOrder";
import AdminGiftOrder from "../../components/AdminGiftOrder/AdminGiftOrder";
import AdminMenu from "../../components/AdminMenu/AdminMenu";

function Admin() {

	const [selectedButton, setSelectedButton] = useState('main');

	const renderComponent = () => {
		switch (selectedButton) {
			case 'main':
				return <AdminMain/>;
			case 'order':
				return <AdminOrder/>;
			case 'gift':
				return <AdminGiftOrder/>;
			default:
				return null;
		}
	};


	return (
		<div className="admin">
			<div className="container-top">
				<div className="__wrapper">
					<div className="__menu_container">
						<AdminMenu setSelectedButton={setSelectedButton}/>
					</div>
					<div className="__content_container">{renderComponent()}</div>
				</div>

			</div>

		</div>
	);
}

export default Admin;