import React, {useState} from 'react';
import "./Catalog.scss";
import Settings from "../../components/Settings/Settings";
import Item from "../../components/Item/Item";
import settingsImg from "../../img/catalogSettings.svg";

function Catalog() {
	const [isSettingsVisible, setIsSettingsVisible] = useState(false);

	const toggleSettingsVisibility = () => {
		setIsSettingsVisible(!isSettingsVisible);
	};
	return (
		<div className="main_catalog">
			<div className="container-top">
				<div className="container">
					<div className="main_title">
						<h1>Каталог</h1>
						<button className="toggle-button" onClick={toggleSettingsVisibility}>
							<img src={settingsImg} alt="img"/>
						</button>
					</div>
					<div className="main_wrapper">
						<Settings isSettingsVisible={isSettingsVisible} setIsSettingsVisible={setIsSettingsVisible}/>
						<Item/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Catalog;