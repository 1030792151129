import React, { useContext, useState, useEffect } from 'react';
import { Context } from "../../index";
import { updateFlower, fetchOneFlower, fetchTypes } from "../../http/flowerAPI";
import { observer } from "mobx-react-lite";
import "./Modal.scss";
import { Cropper } from 'react-cropper';
import "cropperjs/dist/cropper.css";
import { API_URL } from "../../http";
import deleteImg from "../../img/adminDeleteImg.svg";

const UpdateFlower = observer(({ show, onHide, flowerId, reloadData }) => {
	const { item } = useContext(Context);
	const [dropdownActive, setDropdownActive] = useState(false);
	const [name, setName] = useState('');
	const [text, setText] = useState('');
	const [price, setPrice] = useState('');
	const [equipment, setEquipment] = useState('');
	const [files, setFiles] = useState([]);
	const [currentImages, setCurrentImages] = useState([]);
	const [serverError, setServerError] = useState(null);

	const [cropper, setCropper] = useState(null);
	const [isCropping, setIsCropping] = useState(false);
	const [currentFile, setCurrentFile] = useState(null);

	useEffect(() => {
		fetchTypes().then(data => item.setTypes(data));
		if (flowerId) {
			fetchOneFlower(flowerId).then(flower => {
				setName(flower.name);
				setText(flower.text);
				setPrice(flower.price);
				setEquipment(flower.equipment);
				setCurrentImages(flower.img || []);
				item.setSelectedType(item.types.find(type => type.id === flower.type_id));
			});
		}
	}, [flowerId, item]);

	useEffect(() => {
		if (!show) {
			resetModal();
		}
	}, [show]);

	const resetModal = () => {
		setName("");
		setText("");
		setPrice("");
		setEquipment("");
		setFiles([]);
		setCurrentImages([]);
		setServerError(null);
		setIsCropping(false);
		setCurrentFile(null);
	};

	const dropdownItemClick = (type) => {
		item.setSelectedType(type);
		setDropdownActive(false);
	};

	const selectFile = e => {
		const file = e.target.files[0];
		if (file) {
			setCurrentFile(file);
			setIsCropping(true);
		}

	};

	const deleteImage = (index) => {
		if (index < currentImages.length) {
			setCurrentImages(currentImages.filter((_, i) => i !== index));
		} else {
			const fileIndex = index - currentImages.length;
			setFiles(files.filter((_, i) => i !== fileIndex));
		}
	};

	const cropImage = () => {
		if (cropper) {
			cropper.getCroppedCanvas({
				width: 300,
				height: 400,
			}).toBlob((blob) => {
				setFiles([...files, new File([blob], currentFile.name, { type: blob.type })]);
				setIsCropping(false);
				setCurrentFile(null);
			});
		}
	};

	const handleUpdate = async () => {
		try {
			const formData = new FormData();
			formData.append('name', name);
			formData.append('text', text);
			formData.append('price', price);
			formData.append('equipment', equipment);
			formData.append('type_id', item.selectedType.id);
			formData.append('currentImages', JSON.stringify(currentImages));

			files.forEach(file => formData.append('newImg', file));

			const data = await updateFlower(flowerId, formData);
			console.log("Flower updated successfully", data);
			resetModal();
			onHide();
			reloadData();
		} catch (error) {
			if (error.response && error.response.data && error.response.data.message) {
				setServerError(error.response.data.message);
			} else {
				console.error("Failed to create flower", error);
			}
		}
	};

	const allImages = [
		...currentImages.map((image) => ({ src: `${API_URL}/${image}` })),
		...files.map((file) => ({ src: URL.createObjectURL(file) })),
	];

	if (!show) return null;

	return (
		<div className="modalBackdrop">
			<div className="modal">
				{isCropping ? (
					<div className="cropperContainer">
						<Cropper
							src={URL.createObjectURL(currentFile)}
							style={{height: "400px", width: "100%" }}
							aspectRatio={1}
							viewMode={1}
							zoomable={false}
							scalable={false}
							guides={false}
							dragMode="none"
							cropBoxMovable={true}
							cropBoxResizable={false}
							onInitialized={instance => {
								setCropper(instance);
								instance.setCropBoxData({ width: 300, height: 500 });
							}}
						/>
						<div className="modalFooter">
							<button className="footerButton" onClick={cropImage}>Обрезать и добавить</button>
							<button className="footerButton" onClick={() => setIsCropping(false)}>Отмена</button>
						</div>
					</div>
				) : (
					<>
						<div className="modalHeader">
							<h2>Изменить</h2>
							<button className="closeButton" onClick={onHide}>&times;</button>
						</div>
						<div className="modalBody">
							<div className="form">
								<div className="dropdown">
									<button className="dropdownToggle"
									        onClick={() => setDropdownActive(!dropdownActive)}>
										{item.selectedType.name || "Выберите тип букета"}
									</button>
									{dropdownActive && <div className="dropdownMenu">
										{item.types.map(type =>
											<div
												className="dropdownItem"
												onClick={() => dropdownItemClick(type)}
												key={type.id}
											>
												{type.name}
											</div>
										)}
									</div>}
								</div>
								<input
									value={name}
									onChange={e => setName(e.target.value)}
									className="input"
									placeholder="Название"
								/>
								<textarea
									value={text}
									onChange={e => setText(e.target.value)}
									className="textarea"
									placeholder="Описание"
								/>
								<input
									value={price}
									onChange={e => setPrice(e.target.value)}
									className="input"
									placeholder="Цена"
									type="text"
								/>
								<input
									value={equipment}
									onChange={e => setEquipment(e.target.value)}
									className="input"
									placeholder="Комплектация"
									type="text"
								/>
								<div className="customFileInput">
									<input
										id="file"
										type="file"
										onChange={selectFile}
										multiple={true}
										style={{display: 'none'}}
									/>
									<label htmlFor="file" className="fileLabel">
										Выберите изображения
									</label>
									<div className="imagePreviews">
										{allImages.map((image, index) => (
											<div key={index} className="imagePreviewContainer">
												<img
													src={image.src}
													alt="img"
													className="imagePreview"
												/>
												<button className="delete-button" onClick={() => deleteImage(index)}>
													<img src={deleteImg} alt="delete"/>
												</button>
											</div>
										))}
									</div>
									{serverError && (
										<div className="error-message">
											{serverError}
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="modalFooter">
							<button className="footerButton" onClick={handleUpdate}>Сохранить</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
});

export default UpdateFlower;
