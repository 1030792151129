import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../index";
import { createFlower, fetchTypes } from "../../http/flowerAPI";
import { observer } from "mobx-react-lite";
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import "./Modal.scss";
import deleteImg from "../../img/adminDeleteImg.svg";

const CreateFlower = observer(({ show, onHide, reloadData }) => {
	const { item } = useContext(Context);
	const [dropdownActive, setDropdownActive] = useState(false);
	const [name, setName] = useState('');
	const [text, setText] = useState('');
	const [price, setPrice] = useState('');
	const [equipment, setEquipment] = useState('');
	const [files, setFiles] = useState([]);
	const [currentPreview, setCurrentPreview] = useState(null);
	const [errors, setErrors] = useState({});
	const [serverError, setServerError] = useState(null);

	const [cropper, setCropper] = useState(null);
	const [isCropping, setIsCropping] = useState(false);
	const [currentFile, setCurrentFile] = useState(null);

	useEffect(() => {
		fetchTypes().then(data => item.setTypes(data));
	}, []);

	useEffect(() => {
		if (!show) {
			resetModal();
		}
	}, [show]);

	const dropdownItemClick = (type) => {
		item.setSelectedType(type)
		setDropdownActive(false);
	}

	const resetModal = () => {
		setName("");
		setText("");
		setPrice("");
		setEquipment("");
		setFiles([]);
		setCurrentPreview(null);
		setErrors({});
		setServerError(null);
		setIsCropping(false);
		setCurrentFile(null);
	};

	const selectFile = (e) => {
		const file = e.target.files[0];
		if (file) {
			setCurrentFile(file);
			setIsCropping(true);
		}
	};

	const cropImage = () => {
		if (cropper) {
			cropper.getCroppedCanvas({
				width: 300,
				height: 400
			}).toBlob(blob => {
				setFiles([...files, new File([blob], currentFile.name, { type: blob.type })]);
				setIsCropping(false);
				setCurrentFile(null);
			});
		}
	};

	const addProduct = async () => {
		const newErrors = {};
		if (!name) newErrors.name = true;
		if (!text) newErrors.text = true;
		if (!price) newErrors.price = true;
		if (!equipment) newErrors.equipment = true;
		if (files.length === 0) newErrors.files = true;

		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}
		try {
			const formData = new FormData();
			formData.append('name', name);
			formData.append('text', text);
			formData.append('price', price);
			formData.append('equipment', equipment);
			formData.append('type_id', item.selectedType.id);

			files.forEach(file => {
				formData.append('img', file);
			});
			const data = await createFlower(formData);
			console.log("Flower created successfully", data);
			resetModal();
			onHide();
			reloadData();
		} catch (error) {
			if (error.response && error.response.data && error.response.data.message) {
				setServerError(error.response.data.message);
			} else {
				console.error("Failed to create flower", error);
			}
		}
	}

	const deleteImage = (index) => {
		setFiles(files.filter((_, i) => i !== index));
	};

	if (!show) return null;

	return (
		<div className="modalBackdrop">
			<div className="modal">
				{isCropping ? (
					<div className="cropperContainer">
						<Cropper
							src={URL.createObjectURL(currentFile)}
							style={{height: "400px", width: "100%" }}
							aspectRatio={1}
							viewMode={1}
							zoomable={false}
							scalable={false}
							guides={false}
							dragMode="none"
							cropBoxMovable={true}
							cropBoxResizable={false}
							onInitialized={instance => {
								setCropper(instance);
								instance.setCropBoxData({ width: 300, height: 400 });
							}}
						/>
						<div className="modalFooter">
							<button className="footerButton" onClick={cropImage}>Обрезать и добавить</button>
							<button className="footerButton" onClick={() => setIsCropping(false)}>Отмена</button>
						</div>
					</div>
				) : (
					<>
						<div className="modalHeader">
							<h2>Добавить</h2>
							<button className="closeButton" onClick={onHide}>&times;</button>
						</div>
						<div className="modalBody">
							<div className="form">
								<div className="dropdown">
									<button className="dropdownToggle" onClick={() => setDropdownActive(!dropdownActive)}>
										{item.selectedType.name || "Выберите тип"}
									</button>
									{dropdownActive && <div className="dropdownMenu">
										{item.types.map(type =>
											<div
												className="dropdownItem"
												onClick={() => dropdownItemClick(type)}
												key={type.id}
											>
												{type.name}
											</div>
										)}
									</div>}
								</div>
								<input
									value={name}
									onChange={e => setName(e.target.value)}
									className={`input ${errors.name ? 'error' : ''}`}
									placeholder="Название"
								/>
								<textarea
									value={text}
									onChange={e => setText(e.target.value)}
									className={`textarea ${errors.text ? 'error' : ''}`}
									placeholder="Описание"
								/>
								<input
									value={price}
									onChange={e => setPrice(e.target.value)}
									className={`input ${errors.price ? 'error' : ''}`}
									placeholder="Цена"
									type="text"
								/>
								<input
									value={equipment}
									onChange={e => setEquipment(e.target.value)}
									className={`input ${errors.equipment ? 'error' : ''}`}
									placeholder="Комплектация"
									type="text"
								/>
								<div className="customFileInput">
									<input
										id="file"
										type="file"
										onChange={selectFile}
										style={{ display: 'none' }}
										className={`${errors.files ? 'error' : ''}`}
									/>
									<label htmlFor="file" className="fileLabel">
										Выберите изображения
									</label>
									<div className="imagePreviews">
										{files.map((file, index) => (
											<div key={index} className="imagePreviewContainer">
												<img
													src={URL.createObjectURL(file)}
													alt="img"
													className="imagePreview"
												/>
												<button className="delete-button" onClick={() => deleteImage(index)}>
													<img src={deleteImg} alt="img"/>
												</button>
											</div>
										))}
									</div>
									{serverError && (
										<div className="error-message">
											{serverError}
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="modalFooter">
							<button className="footerButton" onClick={addProduct}>Добавить</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
});

export default CreateFlower;
